import React, { useRef } from "react"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"
import * as S from "./second-scene.styles"
import { Container } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"

const SecondScene = ({ title, description, button }) => {
  const ref = useRef()

  return (
    <S.StickyDiv>
      <Controller>
        <div>
          <Scene triggerHook="onLeave" duration={8000} pin>
            {progress => (
              <S.Sticky>
                <S.CustomFade triggerOnce delay={250}>
                  {/*  Timeline for video  */}
                  <Timeline
                    totalProgress={progress}
                    paused
                    target={
                      <S.Animation>
                        <img src="https://admin-rr.aftershock.agency/wp-content/uploads/2021/12/device.png" />
                      </S.Animation>
                    }
                  >
                    {/*  Tween to lower the opacity of the video and width  */}
                    <Tween
                      duration={2}
                      from={{ top: "10%", opacity: 0.4, scale: 0.7 }}
                      to={{ opacity: 1, scale: 0.8 }}
                    />
                    <Tween
                      duration={2}
                      from={{ scale: 0.8 }}
                      to={{ top: "85%", scale: 1.7 }}
                    />
                    <Timeline
                      target={
                        <S.Animation>
                          <Container maxWidth="xl">
                            <S.ContentWrapper>
                              <S.Title>{title}</S.Title>
                              <S.Description>{description}</S.Description>
                              <CustomButton
                                className="blue"
                                href={button?.url}
                                target={button?.target}
                              >
                                {button?.title}
                              </CustomButton>
                            </S.ContentWrapper>
                          </Container>
                        </S.Animation>
                      }
                    >
                      {/*  Tween to put opacity */}
                      <Tween
                        duration={1}
                        from={{ opacity: 0, top: "17%" }}
                        to={{ opacity: 1 }}
                      />
                      <Tween to={{ opacity: 0 }} duration={1} />
                    </Timeline>
                    <Tween to={{ top: "10%", scale: 0.8 }} duration={2} />
                  </Timeline>
                </S.CustomFade>
              </S.Sticky>
            )}
          </Scene>
        </div>
      </Controller>
    </S.StickyDiv>
  )
}

export default SecondScene
