import React, { useState } from "react"
import * as S from "./home-tabs.styles"
import { Grid } from "@mui/material"
import PropTypes from "prop-types"

const HomeTabs = ({ title, subtitle, description, tabs, className }) => {
  const [position, setPosition] = useState(0)
  const handleChange = newValue => {
    setPosition(newValue)
  }

  return (
    <>
      {tabs && (
        <S.CustomGrid container spacing={2}>
          <S.LeftGrid item xs={12} md sm>
            <S.ContentWrapper>
              {title && <S.Title className="large">{title}</S.Title>}
              {subtitle && <S.Title>{subtitle}</S.Title>}
              {description && (
                <S.Description>{tabs[position].text}</S.Description>
              )}
            </S.ContentWrapper>
            <S.TabsWrapper>
              {tabs.map((tab, index) => (
                <S.TabButton
                  className={index === position ? "selected" : "disabled"}
                  onClick={() => handleChange(index)}
                >
                  <S.ButtonWrapper>
                    <span>{tab.title}</span>
                    {index === position && <S.Arrow />}
                  </S.ButtonWrapper>
                </S.TabButton>
                // <S.CustomTab
                //   label={tab.title}
                //   value={index}
                //   className={className}
                // />
              ))}
            </S.TabsWrapper>
          </S.LeftGrid>
          <Grid item xs={12} md sm>
            {tabs.map(
              (tab, index) =>
                position === index && (
                  <S.MediaWrapper>
                    {tab.isVideo ? (
                      <S.Video
                        playsInline
                        autoPlay
                        loop
                        muted
                        src={tab.image.localFile.url}
                      />
                    ) : (
                      <S.TabImage img={tab.image.localFile.url} />
                    )}
                  </S.MediaWrapper>
                )
            )}
          </Grid>
        </S.CustomGrid>
      )}
    </>
  )
}

HomeTabs.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  tabs: PropTypes.array.isRequired,
}

export default HomeTabs
