import React from "react"
import * as S from "./color-banner.styles"
import { Container } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"

const ColorBanner = ({ title, description, button, color }) => {
  return (
    <S.Wrapper>
      <Container>
        <S.BannerWrapper color={color}>
          <S.ContentWrapper>
            {title && <S.Title>{title}</S.Title>}
            {description && <S.Description>{description}</S.Description>}
            {button?.title && (
              <CustomButton
                className={color === "#15151A" ? "blue" : "light"}
                href={button?.url}
                target={button?.target}
              >
                {button?.title}
              </CustomButton>
            )}
          </S.ContentWrapper>
        </S.BannerWrapper>
      </Container>
    </S.Wrapper>
  )
}

export default ColorBanner
