import React from "react"
import * as S from "./faq.styles"
import { Container } from "@mui/material"
import FaqAccordion from "../../components/faq-accordion/faq-accordion.component"

const Faq = ({ title, questions }) => {
  return (
    <S.Wrapper>
      <Container maxWidth="md">
        {title && <S.Title>{title}</S.Title>}
        <FaqAccordion questions={questions} />
      </Container>
    </S.Wrapper>
  )
}

export default Faq
