import React from "react"
import * as S from "./home-cards-grid.styles"
import { Container, Grid } from "@mui/material"
import PropTypes from "prop-types"

const HomeCardsGrid = ({ image, reverse, children, noPaddingTop }) => {
  return (
    <S.Wrapper noPaddingTop={noPaddingTop}>
      <Container maxWidth="xl">
        <Grid container spacing={4} direction={reverse ? "row-reverse" : "row"}>
          <Grid item lg={8} md={12} xs={12}>
            <S.ImageWrapper>
              <Container maxWidth="xl">
                <S.Image img={image} />
              </Container>
            </S.ImageWrapper>
          </Grid>
          <Grid item lg={4} md={12} xs={12}>
            <S.ContentWrapper>{children}</S.ContentWrapper>
          </Grid>
        </Grid>
      </Container>
    </S.Wrapper>
  )
}

HomeCardsGrid.propTypes = {
  image: PropTypes.object,
  reverse: PropTypes.bool,
  noPaddingTop: PropTypes.bool,
}

export default HomeCardsGrid
