import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Grid, Typography } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import Tab from "@mui/material/Tab"
import ArrowNext from "../../images/arrow.svg"
export const Wrapper = styled(SectionWrapper)`
  background-color: #f5f5f5;
  padding: 0;
  .MuiGrid-item {
    background-color: white;
    border-radius: 32px;
    padding-top: 2rem;
    padding-left: 2rem;
  }
  .MuiTabs-indicator {
    display: none;
  }
`

export const TextWrapper = styled.div`
  height: 100%;
  border-radius: 20px;
  padding: 2em;
  background-color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 36px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.content};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  letter-spacing: 0;
  line-height: 32px;
  padding: 1em 0 8em 0;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
    padding-bottom: 1em;
  }
`

export const Image = styled(CustomImage)`
  width: 100%;
  height: 100%;
  min-height: 600px;
  max-width: 440px;
  margin: auto;
  img {
    object-fit: contain !important;
    object-position: bottom;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    min-height: unset;
  }
`
export const LeftWrapper = styled(Grid)`
  padding-right: 2rem;
`
export const TabGrid = styled(Grid)`
  &.MuiGrid-root {
    ${({ theme }) => theme.breakpoints.down("md")} {
      padding: 0;
    }
  }
`

export const TopWrapper = styled(SectionWrapper)`
  background-color: #f5f5f5;
  padding: 0;
  .MuiTabs-indicator {
    display: none;
  }
`
