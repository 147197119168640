import React, { useRef } from "react"
import * as S from "./first-scene.styles"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"
import HeroTitle from "../HomePage/first-scene/hero-title/hero-title.component"
import Video from "../HomePage/first-scene/video/video.component"
import ImageSequence from "../HomePage/first-scene/image-sequence/image-secuence.component"
import IndustrialAutomationSection from "../HomePage/first-scene/industrial-automation-section/industrial-autonation-section.component"

const FirstScene = ({ heroTitle, industrialAutomation, videoUrl }) => {
  const ref = useRef()
  return (
    <S.StickyDiv>
      <HeroTitle title={heroTitle} />
      <Controller>
        <div>
          <Scene triggerHook="onLeave" duration={8000} pin>
            {progress => (
              <S.Sticky>
                <S.CustomFade triggerOnce delay={250}>
                  {/*  Timeline for video  */}
                  <Timeline
                    totalProgress={progress}
                    paused
                    target={
                      <S.Animation>
                        <Video />
                      </S.Animation>
                    }
                  >
                    {/*  Tween to lower the opacity of the video and width  */}
                    <Tween
                      duration={12}
                      from={{ opacity: 1, width: "100%" }}
                      to={{ opacity: 0.3, width: "75%" }}
                    />
                    {/*  Timeline for frame animation  */}
                    <Timeline
                      target={
                        <S.Animation>
                          {/*  Frames Component  */}
                          <ImageSequence ref={ref} progress={progress} />
                        </S.Animation>
                      }
                    >
                      {/* Tween to make visible the frame component   */}
                      <Tween
                        duration={50}
                        from={{ opacity: 0, backgroundColor: "#ffffff" }}
                        to={{ opacity: 1 }}
                      />
                      {/*  Tween to move the component down and increase its size  */}
                      <Tween to={{ top: "60%", scale: 2 }} duration={4} />
                      {/*  Timeline for second scene text and title */}
                      <Timeline
                        target={
                          <S.Animation>
                            <IndustrialAutomationSection
                              {...industrialAutomation}
                            />
                          </S.Animation>
                        }
                      >
                        {/*  Tween to put opacity */}
                        <Tween
                          from={{ opacity: 0, top: "20%" }}
                          to={{ opacity: 1 }}
                          duration={2}
                        />
                        <Tween to={{ opacity: 0 }} duration={4} />
                      </Timeline>
                      <Tween to={{ top: "0", scale: 1 }} duration={3} />
                    </Timeline>
                  </Timeline>
                </S.CustomFade>
              </S.Sticky>
            )}
          </Scene>
        </div>
      </Controller>
    </S.StickyDiv>
  )
}

export default FirstScene
