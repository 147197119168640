import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Wrapper = styled(SectionWrapper)`
  padding: 0;
  background-color: #f5f5f5;
`

export const MainWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.up("md")} {
    position: relative;
  }
`

export const Image = styled(CustomImage)`
  height: 600px;
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    height: 300px;
    img {
      height: 300px;
      object-fit: cover;
    }
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const Title = styled.h1`
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  ${({ theme }) => theme.breakpoints.up("md")} {
    top: 20%;
    left: 50%;
    font-size: ${({ theme }) => theme.typography.pxToRem(60)};
    transform: translate(-50%, -20%);
  }
`

export const GrayWrapper = styled.div`
  height: 700px;
  background-color: #f5f5f5;

  ${({ theme }) => theme.breakpoints.down("md")} {
    display: none;
  }
`

export const CardWrapper = styled.div`
  padding: 2em 0;
  ${({ theme }) => theme.breakpoints.up("md")} {
    position: absolute;
    width: 100%;
    z-index: 3;
    top: 260px;
    padding: 0;
  }
`
