import styled from "styled-components"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"
import { TextField, Typography } from "@mui/material"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(CustomBgImage)`
  height: 300px;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    min-height: calc(100vh - 200px);
  }
`

export const ContentWrapper = styled.div`
  height: 100%;
  position: relative;
`
export const MobileWrapper = styled(SectionWrapper)`
  background-color: black;
  color: white;
  padding: 0;
`
