import images from "./images"
import { useImage } from "../hooks"

const ImagesArray = () => {
  const [AA0, AA0S] = useImage(images[0].image)
  const [AA1, AA1S] = useImage(images[1].image)
  const [AA2, AA2S] = useImage(images[2].image)
  const [AA3, AA3S] = useImage(images[3].image)
  const [AA4, AA4S] = useImage(images[4].image)
  const [AA5, AA5S] = useImage(images[5].image)
  const [AA6, AA6S] = useImage(images[6].image)
  const [AA7, AA7S] = useImage(images[7].image)
  const [AA8, AA8S] = useImage(images[8].image)
  const [AA9, AA9S] = useImage(images[9].image)
  const [AA10, AA10S] = useImage(images[10].image)
  const [AA11, AA11S] = useImage(images[11].image)
  const [AA12, AA12S] = useImage(images[12].image)
  const [AA13, AA13S] = useImage(images[13].image)
  const [AA14, AA14S] = useImage(images[14].image)
  const [AA15, AA15S] = useImage(images[15].image)
  const [AA16, AA16S] = useImage(images[16].image)
  const [AA17, AA17S] = useImage(images[17].image)
  const [AA18, AA18S] = useImage(images[18].image)
  const [AA19, AA19S] = useImage(images[19].image)
  const [AA20, AA20S] = useImage(images[20].image)
  const [AA21, AA21S] = useImage(images[21].image)
  const [AA22, AA22S] = useImage(images[22].image)
  const [AA23, AA23S] = useImage(images[23].image)
  const [AA24, AA24S] = useImage(images[24].image)
  const [AA25, AA25S] = useImage(images[25].image)
  const [AA26, AA26S] = useImage(images[26].image)
  const [AA27, AA27S] = useImage(images[27].image)
  const [AA28, AA28S] = useImage(images[28].image)
  const [AA29, AA29S] = useImage(images[29].image)
  const [AA30, AA30S] = useImage(images[30].image)
  const [AA31, AA31S] = useImage(images[31].image)
  const [AA32, AA32S] = useImage(images[32].image)
  const [AA33, AA33S] = useImage(images[33].image)
  const [AA34, AA34S] = useImage(images[34].image)
  const [AA35, AA35S] = useImage(images[35].image)
  const [AA36, AA36S] = useImage(images[36].image)
  const [AA37, AA37S] = useImage(images[37].image)
  const [AA38, AA38S] = useImage(images[38].image)
  const [AA39, AA39S] = useImage(images[39].image)
  const [AA40, AA40S] = useImage(images[40].image)
  const [AA41, AA41S] = useImage(images[41].image)
  const [AA42, AA42S] = useImage(images[42].image)
  const [AA43, AA43S] = useImage(images[43].image)
  const [AA44, AA44S] = useImage(images[44].image)
  const [AA45, AA45S] = useImage(images[45].image)
  const [AA46, AA46S] = useImage(images[46].image)
  const [AA47, AA47S] = useImage(images[47].image)
  const [AA48, AA48S] = useImage(images[48].image)
  const [AA49, AA49S] = useImage(images[49].image)
  // const [AA50, AA50S] = useImage(images[50].image)
  // const [AA51, AA51S] = useImage(images[51].image)
  // const [AA52, AA52S] = useImage(images[52].image)
  // const [AA53, AA53S] = useImage(images[53].image)
  // const [AA54, AA54S] = useImage(images[54].image)
  // const [AA55, AA55S] = useImage(images[55].image)
  // const [AA56, AA56S] = useImage(images[56].image)
  // const [AA57, AA57S] = useImage(images[57].image)
  // const [AA58, AA58S] = useImage(images[58].image)
  // const [AA59, AA59S] = useImage(images[59].image)
  // const [AA60, AA60S] = useImage(images[60].image)
  // const [AA61, AA61S] = useImage(images[61].image)
  // const [AA62, AA62S] = useImage(images[62].image)
  // const [AA63, AA63S] = useImage(images[63].image)
  // const [AA64, AA64S] = useImage(images[64].image)
  // const [AA65, AA65S] = useImage(images[65].image)
  // const [AA66, AA66S] = useImage(images[66].image)
  // const [AA67, AA67S] = useImage(images[67].image)
  // const [AA68, AA68S] = useImage(images[68].image)
  // const [AA69, AA69S] = useImage(images[69].image)
  // const [AA70, AA70S] = useImage(images[70].image)
  // const [AA71, AA71S] = useImage(images[71].image)
  // const [AA72, AA72S] = useImage(images[72].image)
  // const [AA73, AA73S] = useImage(images[73].image)
  // const [AA74, AA74S] = useImage(images[74].image)
  // const [AA75, AA75S] = useImage(images[75].image)
  // const [AA76, AA76S] = useImage(images[76].image)
  // const [AA77, AA77S] = useImage(images[77].image)
  // const [AA78, AA78S] = useImage(images[78].image)
  // const [AA79, AA79S] = useImage(images[79].image)
  // const [AA80, AA80S] = useImage(images[80].image)
  // const [AA81, AA81S] = useImage(images[81].image)
  // const [AA82, AA82S] = useImage(images[82].image)
  // const [AA83, AA83S] = useImage(images[83].image)
  // const [AA84, AA84S] = useImage(images[84].image)
  // const [AA85, AA85S] = useImage(images[85].image)
  // const [AA86, AA86S] = useImage(images[86].image)
  // const [AA87, AA87S] = useImage(images[87].image)
  // const [AA88, AA88S] = useImage(images[88].image)
  // const [AA89, AA89S] = useImage(images[89].image)
  // const [AA90, AA90S] = useImage(images[90].image)
  // const [AA91, AA91S] = useImage(images[91].image)
  // const [AA92, AA92S] = useImage(images[92].image)
  // const [AA93, AA93S] = useImage(images[93].image)
  // const [AA94, AA94S] = useImage(images[94].image)
  // const [AA95, AA95S] = useImage(images[95].image)
  // const [AA96, AA96S] = useImage(images[96].image)
  // const [AA97, AA97S] = useImage(images[97].image)
  // const [AA98, AA98S] = useImage(images[98].image)
  // const [AA99, AA99S] = useImage(images[99].image)
  // const [AA100, AA100S] = useImage(images[100].image)
  // const [AA101, AA101S] = useImage(images[101].image)
  // const [AA102, AA102S] = useImage(images[102].image)
  // const [AA103, AA103S] = useImage(images[103].image)
  // const [AA104, AA104S] = useImage(images[104].image)
  // const [AA105, AA105S] = useImage(images[105].image)
  // const [AA106, AA106S] = useImage(images[106].image)
  // const [AA107, AA107S] = useImage(images[107].image)
  // const [AA108, AA108S] = useImage(images[108].image)
  // const [AA109, AA109S] = useImage(images[109].image)
  // const [AA110, AA110S] = useImage(images[110].image)
  // const [AA111, AA111S] = useImage(images[111].image)
  // const [AA112, AA112S] = useImage(images[112].image)
  // const [AA113, AA113S] = useImage(images[113].image)
  // const [AA114, AA114S] = useImage(images[114].image)
  // const [AA115, AA115S] = useImage(images[115].image)
  // const [AA116, AA116S] = useImage(images[116].image)
  // const [AA117, AA117S] = useImage(images[117].image)
  // const [AA118, AA118S] = useImage(images[118].image)
  // const [AA119, AA119S] = useImage(images[119].image)
  // const [AA120, AA120S] = useImage(images[120].image)
  // const [AA121, AA121S] = useImage(images[121].image)
  // const [AA122, AA122S] = useImage(images[122].image)
  // const [AA123, AA123S] = useImage(images[123].image)
  // const [AA124, AA124S] = useImage(images[124].image)
  // const [AA125, AA125S] = useImage(images[125].image)
  // const [AA126, AA126S] = useImage(images[126].image)
  // const [AA127, AA127S] = useImage(images[127].image)
  // const [AA128, AA128S] = useImage(images[128].image)
  // const [AA129, AA129S] = useImage(images[129].image)
  // const [AA130, AA130S] = useImage(images[130].image)
  // const [AA131, AA131S] = useImage(images[131].image)
  // const [AA132, AA132S] = useImage(images[132].image)
  // const [AA133, AA133S] = useImage(images[133].image)
  // const [AA134, AA134S] = useImage(images[134].image)
  // const [AA135, AA135S] = useImage(images[135].image)
  // const [AA136, AA136S] = useImage(images[136].image)
  // const [AA137, AA137S] = useImage(images[137].image)
  // const [AA138, AA138S] = useImage(images[138].image)
  // const [AA139, AA139S] = useImage(images[139].image)
  // const [AA140, AA140S] = useImage(images[140].image)
  // const [AA141, AA141S] = useImage(images[141].image)
  // const [AA142, AA142S] = useImage(images[142].image)
  // const [AA143, AA143S] = useImage(images[143].image)
  // const [AA144, AA144S] = useImage(images[144].image)
  // const [AA145, AA145S] = useImage(images[145].image)
  // const [AA146, AA146S] = useImage(images[146].image)
  // const [AA147, AA147S] = useImage(images[147].image)
  // const [AA148, AA148S] = useImage(images[148].image)
  // const [AA149, AA149S] = useImage(images[149].image)
  // const [AA150, AA150S] = useImage(images[150].image)
  // const [AA151, AA151S] = useImage(images[151].image)
  // const [AA152, AA152S] = useImage(images[152].image)
  // const [AA153, AA153S] = useImage(images[153].image)
  // const [AA154, AA154S] = useImage(images[154].image)
  // const [AA155, AA155S] = useImage(images[155].image)
  // const [AA156, AA156S] = useImage(images[156].image)
  // const [AA157, AA157S] = useImage(images[157].image)
  // const [AA158, AA158S] = useImage(images[158].image)
  // const [AA159, AA159S] = useImage(images[159].image)
  // const [AA160, AA160S] = useImage(images[160].image)
  // const [AA161, AA161S] = useImage(images[161].image)
  // const [AA162, AA162S] = useImage(images[162].image)
  // const [AA163, AA163S] = useImage(images[163].image)
  // const [AA164, AA164S] = useImage(images[164].image)
  // const [AA165, AA165S] = useImage(images[165].image)

  const newImages = Array.of(
    [AA0, AA0S],
    [AA1, AA1S],
    [AA2, AA2S],
    [AA3, AA3S],
    [AA4, AA4S],
    [AA5, AA5S],
    [AA6, AA6S],
    [AA7, AA7S],
    [AA8, AA8S],
    [AA9, AA9S],
    [AA10, AA10S],
    [AA11, AA11S],
    [AA12, AA12S],
    [AA13, AA13S],
    [AA14, AA14S],
    [AA15, AA15S],
    [AA16, AA16S],
    [AA17, AA17S],
    [AA18, AA18S],
    [AA19, AA19S],
    [AA20, AA20S],
    [AA21, AA21S],
    [AA22, AA22S],
    [AA23, AA23S],
    [AA24, AA24S],
    [AA25, AA25S],
    [AA26, AA26S],
    [AA27, AA27S],
    [AA28, AA28S],
    [AA29, AA29S],

    [AA30, AA30S],
    [AA31, AA31S],
    [AA32, AA32S],
    [AA33, AA33S],
    [AA34, AA34S],
    [AA35, AA35S],
    [AA36, AA36S],
    [AA37, AA37S],
    [AA38, AA38S],
    [AA39, AA39S],

    [AA40, AA40S],
    [AA41, AA41S],
    [AA42, AA42S],
    [AA43, AA43S],
    [AA44, AA44S],
    [AA45, AA45S],
    [AA46, AA46S],
    [AA47, AA47S],
    [AA48, AA48S],
    [AA49, AA49S]
    //
    // [AA50, AA50S],
    // [AA51, AA51S],
    // [AA52, AA52S],
    // [AA53, AA53S],
    // [AA54, AA54S],
    // [AA55, AA55S],
    // [AA56, AA56S],
    // [AA57, AA57S],
    // [AA58, AA58S],
    // [AA59, AA59S],
    //
    // [AA60, AA60S],
    // [AA61, AA61S],
    // [AA62, AA62S],
    // [AA63, AA63S],
    // [AA64, AA64S],
    // [AA65, AA65S],
    // [AA66, AA66S],
    // [AA67, AA67S],
    // [AA68, AA68S],
    // [AA69, AA69S],
    //
    // [AA70, AA70S],
    // [AA71, AA71S],
    // [AA72, AA72S],
    // [AA73, AA73S],
    // [AA74, AA74S],
    // [AA75, AA75S],
    // [AA76, AA76S],
    // [AA77, AA77S],
    // [AA78, AA78S],
    // [AA79, AA79S],
    //
    // [AA80, AA80S],
    // [AA81, AA81S],
    // [AA82, AA82S],
    // [AA83, AA83S],
    // [AA84, AA84S],
    // [AA85, AA85S],
    // [AA86, AA86S],
    // [AA87, AA87S],
    // [AA88, AA88S],
    // [AA89, AA89S],
    //
    // [AA90, AA90S],
    // [AA91, AA91S],
    // [AA92, AA92S],
    // [AA93, AA93S],
    // [AA94, AA94S],
    // [AA95, AA95S],
    // [AA96, AA96S],
    // [AA97, AA97S],
    // [AA98, AA98S],
    // [AA99, AA99S],
    //
    // [AA100, AA100S],
    // [AA101, AA101S],
    // [AA102, AA102S],
    // [AA103, AA103S],
    // [AA104, AA104S],
    // [AA105, AA105S],
    // [AA106, AA106S],
    // [AA107, AA107S],
    // [AA108, AA108S],
    // [AA109, AA109S],
    //
    // [AA110, AA110S],
    // [AA111, AA111S],
    // [AA112, AA112S],
    // [AA113, AA113S],
    // [AA114, AA114S],
    // [AA115, AA115S],
    // [AA116, AA116S],
    // [AA117, AA117S],
    // [AA118, AA118S],
    // [AA119, AA119S],
    //
    // [AA120, AA120S],
    // [AA121, AA121S],
    // [AA122, AA122S],
    // [AA123, AA123S],
    // [AA124, AA124S],
    // [AA125, AA125S],
    // [AA126, AA126S],
    // [AA127, AA127S],
    // [AA128, AA128S],
    // [AA129, AA129S],
    //
    // [AA130, AA130S],
    // [AA131, AA131S],
    // [AA132, AA132S],
    // [AA133, AA133S],
    // [AA134, AA134S],
    // [AA135, AA135S],
    // [AA136, AA136S],
    // [AA137, AA137S],
    // [AA138, AA138S],
    // [AA139, AA139S],
    //
    // [AA140, AA140S],
    // [AA141, AA141S],
    // [AA142, AA142S],
    // [AA143, AA143S],
    // [AA144, AA144S],
    // [AA145, AA145S],
    // [AA146, AA146S],
    // [AA147, AA147S],
    // [AA148, AA148S],
    // [AA149, AA149S],
    //
    // [AA150, AA150S],
    // [AA151, AA151S],
    // [AA152, AA152S],
    // [AA153, AA153S],
    // [AA154, AA154S],
    // [AA155, AA155S],
    // [AA156, AA156S],
    // [AA157, AA157S],
    // [AA158, AA158S],
    // [AA159, AA159S],
    //
    // [AA160, AA160S],
    // [AA161, AA161S],
    // [AA162, AA162S],
    // [AA163, AA163S],
    // [AA164, AA164S],
    // [AA165, AA165S]
  )
  return newImages
}

export default ImagesArray
