import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { getPageLayout } from "../../get-layout-utils"

export const query = graphql`
  query PageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      isFrontPage
      title
      content
      uri
      slug
      parentId
      seo {
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
          title
        }
      }
      pageBuilder {
        layouts {
          ... on WpPage_Pagebuilder_Layouts_MiscContent {
            ...MiscContent
          }
          ... on WpPage_Pagebuilder_Layouts_ForgeHero {
            ...ForgeHero
          }
          ... on WpPage_Pagebuilder_Layouts_TopBrandsInfoBanner {
            ...TopBrandsInfoBanner
          }
          ... on WpPage_Pagebuilder_Layouts_ImageInfoRow {
            ...ImageInfoRow
          }
          ... on WpPage_Pagebuilder_Layouts_InfoRow {
            ...InfoRow
          }
          ... on WpPage_Pagebuilder_Layouts_ReadyBanner {
            ...ReadyBanner
          }
          ... on WpPage_Pagebuilder_Layouts_ForgeGrid {
            ...ForgeGrid
          }
          ... on WpPage_Pagebuilder_Layouts_CenteredMiscContent {
            ...CenteredMiscContent
          }
          ... on WpPage_Pagebuilder_Layouts_AboutSection {
            ...AboutSection
          }
          ... on WpPage_Pagebuilder_Layouts_LeadershipTeam {
            ...LeadershipTeam
          }
          ... on WpPage_Pagebuilder_Layouts_SalesCarrousel {
            ...SalesCarrousel
          }
          ... on WpPage_Pagebuilder_Layouts_PartnersHero {
            ...PartnersHero
          }
          ... on WpPage_Pagebuilder_Layouts_RobotImageDescription {
            ...RobotImageDescription
          }
          ... on WpPage_Pagebuilder_Layouts_SoftwarePlatform {
            ...SoftwarePlatform
          }
          ... on WpPage_Pagebuilder_Layouts_ServiceDescription {
            ...ServiceDescription
          }
          ... on WpPage_Pagebuilder_Layouts_Careers {
            ...Careers
          }
          ... on WpPage_Pagebuilder_Layouts_CaseStudiesGrid {
            ...CaseStudiesGrid
          }
          ... on WpPage_Pagebuilder_Layouts_ArticlesPage {
            ...ArticlesPage
          }
          ... on WpPage_Pagebuilder_Layouts_DeveloperDescription {
            ...DeveloperDescription
          }
          ... on WpPage_Pagebuilder_Layouts_ProductivityDescription {
            ...ProductivityDescription
          }
          ... on WpPage_Pagebuilder_Layouts_DescriptionAccordion {
            ...DescriptionAccordion
          }
          ... on WpPage_Pagebuilder_Layouts_PlacesMapGrid {
            ...PlacesMapGrid
          }
          ... on WpPage_Pagebuilder_Layouts_Features {
            ...Features
          }
          ... on WpPage_Pagebuilder_Layouts_FirstScene {
            ...FirstScene
          }
          ... on WpPage_Pagebuilder_Layouts_RobotsSlider {
            ...RobotsSlider
          }
          ... on WpPage_Pagebuilder_Layouts_SecondScene {
            ...SecondScene
          }
          ... on WpPage_Pagebuilder_Layouts_InfoSection {
            ...InfoSection
          }
          ... on WpPage_Pagebuilder_Layouts_ProductivityGrid {
            ...ProductivityGrid
          }
          ... on WpPage_Pagebuilder_Layouts_DeploymentSection {
            ...DeploymentSection
          }
          ... on WpPage_Pagebuilder_Layouts_ResultsRow {
            ...ResultsRow
          }
          ... on WpPage_Pagebuilder_Layouts_UseDescription {
            ...UseDescription
          }
          ... on WpPage_Pagebuilder_Layouts_RobotsUsability {
            ...RobotsUsability
          }
          ... on WpPage_Pagebuilder_Layouts_Testimonials {
            ...Testimonials
          }
          ... on WpPage_Pagebuilder_Layouts_DeviceControl {
            ...DeviceControl
          }
          ... on WpPage_Pagebuilder_Layouts_AppMarketplace {
            ...AppMarketplace
          }
          ... on WpPage_Pagebuilder_Layouts_BannerDescription {
            ...BannerDescription
          }
          ... on WpPage_Pagebuilder_Layouts_LocationSlider {
            ...LocationSlider
          }
          ... on WpPage_Pagebuilder_Layouts_ForgeVideoHero {
            ...ForgeVideoHero
          }
          ... on WpPage_Pagebuilder_Layouts_HeroForm {
            ...HeroForm
          }
          ... on WpPage_Pagebuilder_Layouts_PlatformSection {
            ...PlatformSection
          }
          ... on WpPage_Pagebuilder_Layouts_Highlights {
            ...Highlights
          }
          ... on WpPage_Pagebuilder_Layouts_ContactBanner {
            ...ContactBanner
          }
          ... on WpPage_Pagebuilder_Layouts_TabletSection {
            ...TabletSection
          }
          ... on WpPage_Pagebuilder_Layouts_ForgeOsHero {
            ...ForgeOsHero
          }
          ... on WpPage_Pagebuilder_Layouts_ForgeVideo {
            ...ForgeVideo
          }
          ... on WpPage_Pagebuilder_Layouts_ForgeApps {
            ...ForgeApps
          }
          ... on WpPage_Pagebuilder_Layouts_CardsGrid {
            ...CardsGrid
          }
          ... on WpPage_Pagebuilder_Layouts_ForgeDemo {
            ...ForgeDemo
          }
          ... on WpPage_Pagebuilder_Layouts_HomePlatformSection {
            ...HomePlatformSection
          }
          ... on WpPage_Pagebuilder_Layouts_ForgeTabs {
            ...ForgeTabs
          }
          ... on WpPage_Pagebuilder_Layouts_PlatformVideoSection {
            ...PlatformVideoSection
          }
          ... on WpPage_Pagebuilder_Layouts_EmpowerSection {
            ...EmpowerSection
          }
          ... on WpPage_Pagebuilder_Layouts_HomeHero {
            ...HomeHero
          }
          ... on WpPage_Pagebuilder_Layouts_EmpowerVideoSection {
            ...EmpowerVideoSection
          }
          ... on WpPage_Pagebuilder_Layouts_ForgeExperienceSection {
            ...ForgeExperienceSection
          }
          ... on WpPage_Pagebuilder_Layouts_DeviceControlSection {
            ...DeviceControlSection
          }
          ... on WpPage_Pagebuilder_Layouts_SoftwareModule {
            ...SoftwareModule
          }
          ... on WpPage_Pagebuilder_Layouts_EmpowerModule {
            ...EmpowerModule
          }
          ... on WpPage_Pagebuilder_Layouts_FlowChartsSection {
            ...FlowChartsSection
          }
          ... on WpPage_Pagebuilder_Layouts_PlatformModule {
            ...PlatformModule
          }
          ... on WpPage_Pagebuilder_Layouts_ReadyContent {
            ...ReadyContent
          }
          ... on WpPage_Pagebuilder_Layouts_ActivationSection {
            ...ActivationSection
          }
          ... on WpPage_Pagebuilder_Layouts_BuyForgeHero {
            ...BuyForgeHero
          }
          ... on WpPage_Pagebuilder_Layouts_WhereBuyForge {
            ...WhereBuyForge
          }
          ... on WpPage_Pagebuilder_Layouts_SolutionsSection {
            ...SolutionsSection
          }
          ... on WpPage_Pagebuilder_Layouts_ColorBanner {
            ...ColorBanner
          }
          ... on WpPage_Pagebuilder_Layouts_DigitalTransformationSection {
            ...DigitalTransformationSection
          }
          ... on WpPage_Pagebuilder_Layouts_ReadyPlans {
            ...ReadyPlans
          }
          ... on WpPage_Pagebuilder_Layouts_Faq {
            ...Faq
          }
        }
        pageConfiguration {
          hideFooter
          hideHeaderItems
          haveBlackNav
          hideFooterBanner
          hideDescription
          footerBanner {
            title
            description
            variation
            buttonText
            variation
          }
        }
      }
    }
  }
`
const PageTemplate = ({ data }) => {
  const { seo, slug, pageBuilder, title } = data.wpPage
  const layouts = pageBuilder.layouts || []
  return (
    <Layout {...pageBuilder.pageConfiguration} seo={seo} pageTitle={title}>
      {layouts.map(layout => getPageLayout(layout, title))}
    </Layout>
  )
}

export default PageTemplate
