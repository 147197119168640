import styled from "styled-components"
import { Accordion, Typography } from "@mui/material"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

export const CustomAccordion = styled(Accordion)`
  box-shadow: none;
  background-color: transparent;
  padding: 0.5em 0;

  &:before {
    display: none;
  }
  border-bottom: 1px solid rgb(151, 151, 151, 0.5);
  ${({ theme }) => theme.breakpoints.down("xs")} {
    .MuiButtonBase-root {
      padding-left: 0;
    }

    .MuiAccordionDetails-root {
      padding-left: 0;
    }
  }
`

export const SummaryText = styled(Typography)`
  font-weight: 600;
  line-height: 25px;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
`

export const DetailsText = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  font-weight: 500;
  color: rgba(29, 56, 69, 0.75);
`

export const ExpandIcon = styled(ChevronRightIcon)`
  transform: rotate(90deg);
  width: 32px;
  height: 32px;
`
