import React from "react"
import * as S from "./platform-module.styles"
import HomeCardsGrid from "../../components/home-cards-grid/home-cards-grid.component"

const PlatformModule = ({ title, description, image, features }) => {
  return (
    <HomeCardsGrid image={image} reverse>
      <S.TopWrapper>
        {title && <S.Title>{title}</S.Title>}
        {description && <S.Description>{description}</S.Description>}
        {features.map(({ feature }, index) => (
          <S.Feature key={index - feature}>{feature}</S.Feature>
        ))}
      </S.TopWrapper>
    </HomeCardsGrid>
  )
}

export default PlatformModule
