import React from "react"
import * as S from "./faq-accordion.styles"
import { AccordionDetails, AccordionSummary } from "@mui/material"
import parse from "html-react-parser"

const FaqAccordion = ({ questions }) => {
  const [expanded, setExpanded] = React.useState("panel1")

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  if (!questions) return null

  return (
    <>
      {questions.map(({ question, answer }, index) => (
        <S.CustomAccordion
          expanded={expanded === `panel-${index}`}
          onChange={handleChange(`panel-${index}`)}
        >
          <AccordionSummary
            id={`panel-${index}`}
            expandIcon={
              expanded === `panel ${index}` ? (
                <S.ExpandIcon />
              ) : (
                <S.ExpandIcon />
              )
            }
          >
            <S.SummaryText>{question}</S.SummaryText>
          </AccordionSummary>
          <AccordionDetails>
            <S.DetailsText>{parse(answer)}</S.DetailsText>
          </AccordionDetails>
        </S.CustomAccordion>
      ))}

      {/*{questions.map((question, answer, index) => (*/}
      {/*  <S.CustomAccordion*/}
      {/*    expanded={expanded === `panel ${index}`}*/}
      {/*    onChange={handleChangeAccordion(`panel ${index}`)}*/}
      {/*  >*/}
      {/*    <AccordionSummary*/}
      {/*      expandIcon={*/}
      {/*        expanded === `panel ${index}` ? (*/}
      {/*          <ChevronRightIcon />*/}
      {/*        ) : (*/}
      {/*          <ChevronRightIcon />*/}
      {/*        )*/}
      {/*      }*/}
      {/*    >*/}
      {/*      {question && <S.SummaryText>{question}</S.SummaryText>}*/}
      {/*    </AccordionSummary>*/}
      {/*    <AccordionDetails>*/}
      {/*      {answer && <S.DetailsText>{answer}</S.DetailsText>}*/}
      {/*    </AccordionDetails>*/}
      {/*  </S.CustomAccordion>*/}
      {/*))}*/}
    </>
  )
}

export default FaqAccordion
