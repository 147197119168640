import React from "react"
import Sequence from "./sequence/sequence.component"
import * as S from "./image-secuence.styles"

const ImageSequence = ({ ref, progress }) => {
  return (
    <S.FrameWrapper>
      <Sequence ref={ref} progress={progress} />
    </S.FrameWrapper>
  )
}

export default ImageSequence
