import React from "react"
import MiscContent from "./layouts/MiscContent"
import ForgeHero from "./layouts/ForgeHero"
import TopBrandsInfoBanner from "./layouts/TopBrandsInfoBanner"
import ImageInfoRow from "./layouts/ImageInfoRow"
import InfoRow from "./layouts/InfoRow"
import ReadyBanner from "./layouts/ReadyBanner"
import ForgeGrid from "./layouts/ForgeGrid"
import CenteredMiscContent from "./layouts/CenteredMiscContent"
import AboutSection from "./layouts/AboutSection"
import LeadershipTeam from "./layouts/LeadershipTeam"
import SalesCarrousel from "./layouts/SalesCarrousel"
import PartnersHero from "./layouts/PartnersHero"
import RobotImageDescription from "./layouts/RobotImageDescription"
import SoftwarePlatform from "./layouts/SoftwarePlatform"
import ServiceDescription from "./layouts/ServiceDescription"
import Careers from "./layouts/Careers"
import CaseStudiesGrid from "./layouts/CaseStudiesGrid"
import ArticlesPage from "./layouts/ArticlesPage"
import DeveloperDescription from "./layouts/DeveloperDescription"
import ProductivityDescription from "./layouts/ProductivityDescription"
import DescriptionAccordion from "./layouts/DescriptionAccordion"
import PlacesMapGrid from "./layouts/PlacesMapGrid"
import Features from "./layouts/Features"
import FirstScene from "./layouts/FirstScene"
import RobotsSlider from "./layouts/Robots Slider"
import SecondScene from "./layouts/SecondScene"
import InfoSection from "./layouts/InfoSection"
import ProductivityGrid from "./layouts/ProductivityGrid"
import DeploymentSection from "./layouts/DeploymentSection"
import ResultsRow from "./layouts/ResultsRow"
import UseDescription from "./layouts/UseDescription"
import RobotsUsability from "./layouts/RobotsUsability"
import Testimonials from "./layouts/Testimonials"
import DeviceControl from "./layouts/DeviceControl"
import AppMarketplace from "./layouts/AppMarketplace"
import BannerDescription from "./layouts/BannerDescription"
import LocationSlider from "./layouts/LocationSlider"
import ForgeVideoHero from "./layouts/ForgeVideoHero"
import HeroForm from "./layouts/HeroForm"
import PlatformSection from "./layouts/PlatformSection"
import Highlights from "./layouts/Highlights"
import ContactBanner from "./layouts/ContactBanner"
import TabletSection from "./layouts/TabletSection"
import ForgeOsHero from "./layouts/ForgeOSHero"
import ForgeVideo from "./layouts/ForgeVideo"
import ForgeApps from "./layouts/ForgeApps"
import CardsGrid from "./layouts/CardsGrid"
import ForgeDemo from "./layouts/ForgeDemo"
import HomePlatformSection from "./layouts/HomePlatformSection"
import ForgeTabs from "./layouts/ForgeTabs"
import PlatformVideoSection from "./layouts/PlatformVideoSection"
import EmpowerSection from "./layouts/EmpowerSection"
import HomeHero from "./layouts/HomeHero"
import EmpowerVideoSection from "./layouts/EmpowerVideoSection"
import ForgeExperienceSection from "./layouts/Forge-Experience-Section"
import DeviceControlSection from "./layouts/DeviceControlSection"
import SoftwareModule from "./layouts/SoftwareModule"
import EmpowerModule from "./layouts/EmpowerModule"
import FlowChartsSection from "./layouts/FlowChartsSection"
import PlatformModule from "./layouts/PlatformModule"
import ReadyContent from "./layouts/ReadyContent"
import ActivationSection from "./layouts/ActivationSection"
import BuyForgeHero from "./layouts/BuyForgeHero"
import WhereBuyForge from "./layouts/where-buy-forge"
import SolutionsSection from "./layouts/SolutionsSection"
import ColorBanner from "./layouts/ColorBanner"
import DigitalTransformationSection from "./layouts/DigitalTransformationSection"
import ReadyPlans from "./layouts/ReadyPlans"
import Faq from "./layouts/Faq"

export const getPageLayout = (layout, title) => {
  const layoutName = layout?.fieldGroupName?.split("_").pop().trim()
  if (!layoutName) return null
  switch (layoutName) {
    case "MiscContent":
      return <MiscContent {...layout} />
    case "ForgeHero":
      return <ForgeHero {...layout} pageTitle={title} />
    case "TopBrandsInfoBanner":
      return <TopBrandsInfoBanner {...layout} pageTitle={title} />
    case "ImageInfoRow":
      return <ImageInfoRow {...layout} />
    case "InfoRow":
      return <InfoRow {...layout} />
    case "ReadyBanner":
      return <ReadyBanner {...layout} />
    case "ForgeGrid":
      return <ForgeGrid {...layout} />
    case "CenteredMiscContent":
      return <CenteredMiscContent {...layout} />
    case "AboutSection":
      return <AboutSection {...layout} />
    case "LeadershipTeam":
      return <LeadershipTeam {...layout} />
    case "SalesCarrousel":
      return <SalesCarrousel {...layout} />
    case "PartnersHero":
      return <PartnersHero {...layout} pageTitle={title} />
    case "RobotImageDescription":
      return <RobotImageDescription {...layout} />
    case "SoftwarePlatform":
      return <SoftwarePlatform {...layout} />
    case "ServiceDescription":
      return <ServiceDescription {...layout} />
    case "Careers":
      return <Careers {...layout} />
    case "CaseStudiesGrid":
      return <CaseStudiesGrid {...layout} />
    case "ArticlesPage":
      return <ArticlesPage {...layout} pageTitle={title} />
    case "DeveloperDescription":
      return <DeveloperDescription {...layout} />
    case "ProductivityDescription":
      return <ProductivityDescription {...layout} />
    case "DescriptionAccordion":
      return <DescriptionAccordion {...layout} />
    case "PlacesMapGrid":
      return <PlacesMapGrid {...layout} />
    case "Features":
      return <Features {...layout} />
    case "FirstScene":
      return <FirstScene {...layout} />
    case "RobotsSlider":
      return <RobotsSlider {...layout} />
    case "SecondScene":
      return <SecondScene {...layout} />
    case "InfoSection":
      return <InfoSection {...layout} />
    case "ProductivityGrid":
      return <ProductivityGrid {...layout} />
    case "DeploymentSection":
      return <DeploymentSection {...layout} />
    case "ResultsRow":
      return <ResultsRow {...layout} />
    case "UseDescription":
      return <UseDescription {...layout} />
    case "RobotsUsability":
      return <RobotsUsability {...layout} />
    case "Testimonials":
      return <Testimonials {...layout} />
    case "DeviceControl":
      return <DeviceControl {...layout} />
    case "AppMarketplace":
      return <AppMarketplace {...layout} />
    case "BannerDescription":
      return <BannerDescription {...layout} />
    case "LocationSlider":
      return <LocationSlider {...layout} />
    case "ForgeVideoHero":
      return <ForgeVideoHero {...layout} />
    case "HeroForm":
      return <HeroForm {...layout} />
    case "PlatformSection":
      return <PlatformSection {...layout} />
    case "Highlights":
      return <Highlights {...layout} />
    case "ContactBanner":
      return <ContactBanner {...layout} />
    case "TabletSection":
      return <TabletSection {...layout} />
    case "ForgeOsHero":
      return <ForgeOsHero {...layout} />
    case "ForgeVideo":
      return <ForgeVideo {...layout} />
    case "ForgeApps":
      return <ForgeApps {...layout} />
    case "CardsGrid":
      return <CardsGrid {...layout} />
    case "ForgeDemo":
      return <ForgeDemo {...layout} />
    case "HomePlatformSection":
      return <HomePlatformSection {...layout} />
    case "ForgeTabs":
      return <ForgeTabs {...layout} />
    case "PlatformVideoSection":
      return <PlatformVideoSection {...layout} />
    case "EmpowerSection":
      return <EmpowerSection {...layout} />
    case "HomeHero":
      return <HomeHero {...layout} />
    case "EmpowerVideoSection":
      return <EmpowerVideoSection {...layout} />
    case "ForgeExperienceSection":
      return <ForgeExperienceSection {...layout} />
    case "DeviceControlSection":
      return <DeviceControlSection {...layout} />
    case "SoftwareModule":
      return <SoftwareModule {...layout} />
    case "EmpowerModule":
      return <EmpowerModule {...layout} />
    case "FlowChartsSection":
      return <FlowChartsSection {...layout} />
    case "PlatformModule":
      return <PlatformModule {...layout} />
    case "ReadyContent":
      return <ReadyContent {...layout} />
    case "ActivationSection":
      return <ActivationSection {...layout} />
    case "BuyForgeHero":
      return <BuyForgeHero {...layout} />
    case "WhereBuyForge":
      return <WhereBuyForge {...layout} />
    case "SolutionsSection":
      return <SolutionsSection {...layout} />
    case "ColorBanner":
      return <ColorBanner {...layout} />
    case "DigitalTransformationSection":
      return <DigitalTransformationSection {...layout} />
    case "ReadyPlans":
      return <ReadyPlans {...layout} />
    case "Faq":
      return <Faq {...layout} />
  }
}
