import React from "react"
import * as S from "./video.styles"

const Video = ({}) => {
  return (
    <S.HeroVideo
      playsInline
      autoPlay
      loop
      muted
      src="https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/forge-hero-2.mp4"
    />
  )
}

export default Video
