import styled from "styled-components"
import { Typography } from "@mui/material"
import CustomImage from "../custom-image/custom-image.component"

export const TopWrapper = styled.div`
  height: 650px;
  background-color: white;
  width: 900px;
  overflow-y: scroll;
  padding: 0 4em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 600px;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 350px;
    padding: 0 2em;
  }
`

export const Title = styled.h2`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(68)};
  line-height: 68px;
  font-weight: 500;
  ${({ theme }) => theme.breakpoints.down("md")} {
    line-height: normal;
    font-size: 2rem;
  }
`

export const Description = styled(Typography)`
  text-align: center;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: 24px;
  padding: 2em 0;
  ${({ theme }) => theme.breakpoints.down("md")} {
    line-height: normal;
  }
`

export const LeftText = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 500;
  line-height: 36px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    line-height: normal;
    font-size: 1.5rem;
  }
`

export const Image = styled(CustomImage)`
  padding: 1em 0 2em;
  max-width: 500px;
  margin: auto;
  img {
    max-width: 500px;
  }
`
