import React from "react"
import * as S from "./digital-transformation-section.styles"
import { Container, Grid } from "@mui/material"
import DigitalCard from "../../components/digital-card/digital-card.component"

const DigitalTransformationSection = ({ title, cards }) => {
  return (
    <S.Wrapper>
      <Container>
        {title && <S.Title>{title}</S.Title>}
        <Grid container spacing={4}>
          {cards.map(({ title, description, subtitle, icon }, index) => (
            <Grid key={`digital-card-${index}`} item xs={12} sm={12} md={4}>
              <DigitalCard
                title={title}
                description={description}
                subtitle={subtitle}
                icon={icon}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </S.Wrapper>
  )
}

export default DigitalTransformationSection
