import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Typography } from "@mui/material"

export const Wrapper = styled(SectionWrapper)`
  background-color: #f5f5f5;
`

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.typography.pxToRem(42)};
  font-family: ${({ theme }) => theme.fonts.secondary};
  margin-bottom: 1em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
    margin-bottom: 1em;
  }
`

export const LeftTitle = styled.h3`
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 2em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2em 0;
`

export const Description = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: 24px;
  color: ${({ theme }) => theme.palette.text.content};
  margin-bottom: 4em;
`

export const ExternalLink = styled.a`
  text-decoration: none;
`

export const TextWrapper = styled.div`
  max-width: 750px;
  margin: auto;
  text-align: center;
`
