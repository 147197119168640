import React from "react"
import * as S from "./ready-plans.styles"
import { Container, Grid } from "@mui/material"
import PlansTable from "../../components/plans-table/plans-table.component"
import CustomButton from "../../components/custom-button/custom-button.component"

const ReadyPlans = ({ title, leftTitle, leftDescription, button }) => {
  return (
    <S.Wrapper>
      <Container>
        <S.TextWrapper>
          {title && <S.Title>{title}</S.Title>}
          {leftTitle && <S.LeftTitle>{leftTitle}</S.LeftTitle>}
          {leftDescription && <S.Description>{leftDescription}</S.Description>}
        </S.TextWrapper>
        <PlansTable />
        {button?.title && (
          <S.ButtonWrapper>
            <S.ExternalLink href="/Customer-Success-Plans.pdf" download>
              <CustomButton className="black-transparent">
                {button?.title}
              </CustomButton>
            </S.ExternalLink>
          </S.ButtonWrapper>
        )}
      </Container>
    </S.Wrapper>
  )
}

export default ReadyPlans
