import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Typography } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Wrapper = styled(SectionWrapper)``

export const ContentWrapper = styled.div`
  max-width: 405px;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ImageWrapper = styled.div`
  box-shadow: 0 20px 60px 0 #1c72e8;
  width: 396px;
  height: 594px;
  margin: auto;
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 36px;
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.content};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  letter-spacing: 0;
  line-height: 32px;
  padding: 1em 0;
`

export const Image = styled(CustomImage)`
  img {
    width: 100%;
    height: 100%;
  }
`

export const RightWrapper = styled.div`
  width: 396px;
  height: 594px;
  margin: auto;
`
