import React from "react"
import * as S from "./map-dialog.styles"
import CancelIcon from "../../assets/blue-close.svg"

const MapDialog = ({ handleClose, open, children }) => {
  return (
    <S.Wrapper onClose={handleClose} open={open}>
      <S.CloseIcon onClick={handleClose}>
        <CancelIcon />
      </S.CloseIcon>
      {children}
    </S.Wrapper>
  )
}

export default MapDialog
