import React from "react"
import * as S from "./plans-table.styles"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

function createData(plan, basic, production, premier, premierPlus) {
  return { plan, basic, production, premier, premierPlus }
}

const rows = [
  createData("One Year of Software Updates", true, true, true, true),
  createData("Success Planning", false, true, true, true),
  createData("Task Optimization & Best Practices", false, true, true, true),
  createData("Proactive Support", false, true, true, true),
  createData(
    "Support Hours",
    "N/A",
    "Mon-Fri 8am-6pm EST",
    "Mon-Fri 8am-6pm EST",
    "Mon-Fri 8am-6pm EST"
  ),
  createData(
    "Phone / Email Support for General Troubleshooting",
    false,
    true,
    true,
    true
  ),
  createData(
    "Unlimited Access to Digital Learning with READY Academy",
    true,
    true,
    true,
    true
  ),
  createData(
    "Eligible for Hourly READY Onsite Professional Services Including: Training, Service and Installation",
    false,
    true,
    true,
    true
  ),
  createData(
    "Includes One Day of Onsite Professional Services",
    false,
    false,
    true,
    true
  ),
]

const PlansTable = ({}) => {
  return (
    <S.CustomTableContainer
      sx={{
        "&::-webkit-scrollbar": {
          backgroundColor: "transparent",
          width: "5px",
          height: "5px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "20px",
          backgroundColor: "rgba(9, 9, 11, 0.5)",
        },
        "&::-webkit-scrollbar-corner": {
          backgroundColor: "transparent",
        },
      }}
    >
      <Table
        sx={{
          minWidth: 650,
          // height: "max-content"
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell />
            <S.TitleTableCell align="center">Basic</S.TitleTableCell>
            <S.TitleTableCell align="center">Production</S.TitleTableCell>
            <S.TitleTableCell align="center">Premier</S.TitleTableCell>
            <S.TitleTableCell align="center">PremierPlus</S.TitleTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow
              key={row.plan}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <S.ContentTableCell component="th" scope="row">
                {row.plan}
              </S.ContentTableCell>
              <S.ContentTableCell align="center">
                {typeof row.basic === "boolean" ? (
                  <>{row.basic ? <S.Check /> : <S.Cross />}</>
                ) : (
                  <span>{row.basic}</span>
                )}
              </S.ContentTableCell>
              <S.ContentTableCell align="center">
                {typeof row.production === "boolean" ? (
                  <>{row.production ? <S.Check /> : <S.Cross />}</>
                ) : (
                  <span>{row.production}</span>
                )}
              </S.ContentTableCell>
              <S.ContentTableCell align="center">
                {typeof row.premier === "boolean" ? (
                  <>{row.premier ? <S.Check /> : <S.Cross />}</>
                ) : (
                  <span>{row.premier}</span>
                )}
              </S.ContentTableCell>
              <S.ContentTableCell align="center">
                {typeof row.premierPlus === "boolean" ? (
                  <>{row.premierPlus ? <S.Check /> : <S.Cross />}</>
                ) : (
                  <span>{row.premierPlus}</span>
                )}
              </S.ContentTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </S.CustomTableContainer>
  )
}

export default PlansTable
