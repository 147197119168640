import styled from "styled-components"
import CustomImage from "../custom-image/custom-image.component"
import { Typography } from "@mui/material"

export const CardWrapper = styled.div``

export const Icon = styled(CustomImage)`
  width: 48px;
  height: 48px;
  margin-bottom: 2em;
`

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  font-weight: 500;
  line-height: 36px;
  max-width: 265px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const Subtitle = styled.h4`
  padding: 1em 0 0.5em;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  color: ${({ theme }) => theme.palette.text.content};
  font-weight: 500;
  line-height: 24px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.2rem;
    line-height: normal;
  }
`

export const Description = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: 24px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
`
