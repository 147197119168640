import styled from "styled-components"
import { Typography } from "@mui/material"

export const Title = styled.h2`
  color: #09090b;
  font-family: Kanit, sans-serif;
  font-size: 44px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
`

export const Description = styled(Typography)`
  color: #566171;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
`
