import styled from "styled-components"
import CustomButton from "../custom-button/custom-button.component"

import WhiteIcon from "../../images/white-circle-icon.svg"
import BlackIcon from "../../images/black-circle-icon.svg"
import { ButtonBase } from "@mui/material"

export const Button = styled(ButtonBase)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 25px;
  transition: 0.3s all ease;
  margin-bottom: 1em;
  padding: 0.2em 1em;
  &.active {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    > div {
      background: url(${WhiteIcon}) no-repeat center center;
    }
    > span {
      color: white;
    }
  }
`
export const ButtonWrapper = styled.div``

export const Text = styled.span`
  color: black;
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  font-weight: 500;
  line-height: 24px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
`

export const IconWrapper = styled.div`
  height: 40px;
  width: 40px;
  background-size: contain;
  background: url(${BlackIcon}) no-repeat center center;
`
