import React from "react"
import * as S from "./kit-card.styles"
import parse from "html-react-parser"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

const KitCard = ({ title, description, image, features, button, spacing }) => {
  const staticQuery = useStaticQuery(graphql`
    query {
      forgeLogo: file(relativePath: { eq: "forge-buy.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  return (
    <S.Card className={!spacing ? "spacingTop" : ""}>
      <S.CardContent>
        <S.ForgeIcon img={staticQuery.forgeLogo} />
        {title && <S.Title> {parse(title)}</S.Title>}
        <S.KitImage img={image} />
        {description && <S.Description>{parse(description)}</S.Description>}
        <S.Description>
          <span>Best for</span>
          <ul>
            {features.map(({ feature }, index) => (
              <li key={`feature-${index}`}>{feature}</li>
            ))}
          </ul>
        </S.Description>
        {button?.title && button?.url && (
          <S.Button
            className="black-transparent"
            href={button?.url}
            target={button?.target}
          >
            {button?.title}
          </S.Button>
        )}
      </S.CardContent>
    </S.Card>
  )
}

KitCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  features: PropTypes.array,
  button: PropTypes.object,
}

export default KitCard
