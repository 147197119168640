import React from "react"
import * as S from "./digital-card.styles"

const DigitalCard = ({ title, subtitle, description, icon }) => {
  return (
    <S.CardWrapper>
      <S.Icon img={icon} />
      {title && <S.Title>{title}</S.Title>}
      {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
      {description && <S.Description>{description}</S.Description>}
    </S.CardWrapper>
  )
}

export default DigitalCard
