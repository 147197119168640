import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography
} from "@mui/material"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"

export const Wrapper = styled(SectionWrapper)``

export const Title = styled.h2`
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.content};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  letter-spacing: 0;
  line-height: 24px;
  padding: 1em 0;
`

export const ContentWrapper = styled.div`
  max-width: 537px;
`

export const FeatureWrapper = styled.div`
  background-color: white;
  transition: all 1s ease-in-out;

  &.active {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`

/*Accordion*/
export const CustomAccordion = styled(Accordion)`
  width: 100%;

  &.MuiPaper-root {
    box-shadow: none;
    margin: 0;

    &:before {
      background-color: transparent;
    }
  }


`

export const CustomAccSummary = styled(AccordionSummary)`
  background-color: white;
  &.MuiAccordionSummary-content {
      margin: 0!important;
  }
  &.Mui-expanded {
    display: none; 
  }
`

export const AccordionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(151, 151, 151, 0.5);
`

export const ItemWrapper = styled(Container)`
  &.summaryAccordion {
    display: flex;
    justify-content: space-between;
    //border-bottom: 1px solid #979797;
    //opacity: 0.5;
    //width: 70%;
    //float: right;
  }

  &.summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    padding-top: ${({ theme }) => theme.typography.pxToRem(31)};
    padding-bottom: ${({ theme }) => theme.typography.pxToRem(31)};
  }
`

export const ItemTitle = styled.h2`
  color: #09090b;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding: 1em 0;
  max-width: 264px;
  width: 50%;

  &.titleDetails {
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: ${({ theme }) => theme.typography.pxToRem(44)};
    line-height: ${({ theme }) => theme.typography.pxToRem(48)};
    color: ${({ theme }) => theme.palette.secondary.main};
    font-weight: 500;
  }

  &.titleSummary {
    color: #09090b;
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    padding: 1em 0;
    max-width: 264px;
    width: 80%;
  }
`

export const ItemDescription = styled.p`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  color: ${({ theme }) => theme.palette.text.secondary};

  &.titleDetails {
    font-family: ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.palette.primary.main};
    max-width: 516px;
    width: 100%;
  }

  &.descriptionSummary {
    width: 20%;
    text-align: right;
    padding-right: ${({ theme }) => theme.typography.pxToRem(69)};
  }

  &.counterSummary {
    opacity: 0.5;
    color: #000000;
    font-family: ${({ theme }) => theme.fonts.tertiary};
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    letter-spacing: 0;
    line-height: 21px;
  }
`

export const CustomAccDetails = styled(AccordionDetails)`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  padding: 0;
  margin: 0;
`

export const Icon = styled(AddCircleOutlineIcon)`
  color: white;
  transform: rotate(45deg);
  cursor: pointer;
`

export const DescriptionWrapper = styled.div`
  padding: 2em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
`

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 666px;
`

export const DescriptionCounter = styled.span`
  color: #ffffff;
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  letter-spacing: 0;
  line-height: 24px;
`

export const LeftTitle = styled.h2`
  color: #ffffff;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 36px;
  padding: 0.5em 0 1em;
`


export const LeftDescription = styled(Typography)`
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
`

export const CloseIcon = styled(CancelOutlinedIcon)`
  position: absolute;
  top: 3.2em;
  right: 10px;
  fill: white;
  cursor: pointer;
`
