import styled from "styled-components"
import { Dialog, IconButton } from "@mui/material"

export const Wrapper = styled(Dialog)`
  .MuiPaper-root {
    max-width: 900px;
    background-color: white;
    position: relative;
    overflow: unset;
    padding: 3em 0;
  }
`

export const CloseIcon = styled(IconButton)`
  position: absolute;
  top: -16px;
  right: -16px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  padding: 0;
  svg {
    fill: ${({ theme }) => theme.palette.secondary.main};
    width: 100%;
    height: 100%;
  }
`
