import styled from "styled-components"
import { Typography } from "@mui/material"

export const TopWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 2em;
  }
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 36px;
  margin-bottom: 1em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.content};
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  letter-spacing: 0;
  line-height: 32px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
    margin-bottom: 1em;
  }
`

export const Feature = styled.span`
  color: ${({ theme }) => theme.palette.text.content};
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  letter-spacing: 0;
  line-height: 32px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
    margin-bottom: 1em;
  }
`
