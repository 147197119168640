import React from "react"
import * as S from "./industrial-automation-section.styles"
import { Container, Grid } from "@mui/material"

const IndustrialAutomationSection = ({ title, description }) => {
  return (
    <Container>
      <Grid container>
        <Grid xs={6}>{title && <S.Title>{title}</S.Title>}</Grid>
        <Grid xs={6}>
          {description && <S.Description>{description}</S.Description>}
        </Grid>
      </Grid>
    </Container>
  )
}

export default IndustrialAutomationSection
