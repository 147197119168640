import styled from "styled-components"
import TextField from "@mui/material/TextField"
import { Typography } from "@mui/material"

export const TextWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 650px;
  margin: auto;
  padding-bottom: 2em;
  ${({ theme }) => theme.breakpoints.up("md")} {
    min-width: 650px;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: block;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -75%);
  }
`
export const Title = styled.h1`
  color: white;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.secondary};
  line-height: normal;
  padding-bottom: 2rem;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(44)};
    font-weight: 500;
    letter-spacing: 0;
    line-height: 48px;
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
  }
`
export const CustomInput = styled(TextField)`
  background-color: black;
  border-radius: 20px;
  border: 1px solid white;
  color: white;
  padding: 1em 2em 0;
  textarea {
    color: white;
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2em;
`
export const CustomContainer = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1rem;
  h2 {
    color: white;
    font-weight: normal;
  }
  svg {
    color: white;
  }
`
export const Error = styled(Typography)`
  color: #b53737;
  font-weight: 500;
  margin-top: 1em;
`
