import React, { useState } from "react"
import * as S from "./where-buy-forge.styles"
import { ButtonBase, Container, Grid } from "@mui/material"
import parse from "html-react-parser"
import MapDialog from "../../components/map-dialog/map-dialog.component"
import ResellerMap from "../../components/reseller-map/reseller-map.component"
import CustomLink from "../../components/custom-link/custom-link.component"
import DistributorMap from "../../components/distributor-map/distributor-map.component"

const WhereBuyForge = ({
  title,
  description,
  cards,
  // resellersInfo,
  // distributorsInfo,
}) => {
  const [open, setOpen] = useState(false)
  // const [variation, setVariation] = useState("")
  //
  const handleOpen = variation => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <S.Wrapper>
      <S.TopWrapper>
        <Container>
          <S.TextWrapper>
            {title && <S.Title>{title}</S.Title>}
            {description && <S.Description>{parse(description)}</S.Description>}
          </S.TextWrapper>
        </Container>
      </S.TopWrapper>
      <S.CardsWrapper>
        <Container>
          <Grid spacing={4} container justifyContent={"center"}>
            {cards.map(({ title, description, link, type }, index) => (
              <S.CustomGrid item xs={12} md={6} key={`buy-card-${index}`}>
                {type === "link" ? (
                  <CustomLink url={link}>
                    <S.Card>
                      {title && <S.CardTitle>{title}</S.CardTitle>}
                      {description && (
                        <S.CardDescription>
                          {parse(description)}
                        </S.CardDescription>
                      )}
                    </S.Card>
                  </CustomLink>
                ) : (
                  <S.Card onClick={handleOpen}>
                    {title && <S.CardTitle>{title}</S.CardTitle>}
                    {description && (
                      <S.CardDescription>
                        {parse(description)}
                      </S.CardDescription>
                    )}
                  </S.Card>
                )}
              </S.CustomGrid>
            ))}
          </Grid>
        </Container>
      </S.CardsWrapper>
      <MapDialog open={open} handleClose={handleClose}>
        <DistributorMap />
      </MapDialog>
    </S.Wrapper>
  )
}

export default WhereBuyForge
