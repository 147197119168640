import React from "react"
import * as S from "./software-module.styles"
import { Container, Grid } from "@mui/material"

const SoftwareModule = ({ image, title, description, tablet, reverse }) => {
  return (
    <>
      <S.Wrapper>
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <S.Image img={image} />
            </Grid>
            <Grid item xs={12} md={4}>
              <S.TopWrapper>
                <S.ContentWrapper>
                  {title && <S.Title>{title}</S.Title>}
                  {description && <S.Description>{description}</S.Description>}
                </S.ContentWrapper>
                {tablet && <S.Tablet img={tablet} />}
              </S.TopWrapper>
            </Grid>
          </Grid>
        </Container>
      </S.Wrapper>
    </>
  )
}

export default SoftwareModule
