import styled from "styled-components"
import SectionWrapper from "../section-wrapper/section-wrapper.component"
import CustomImage from "../custom-image/custom-image.component"

export const Wrapper = styled(SectionWrapper)`
  background-color: #f5f5f5;

  ${({ noPaddingTop }) =>
    noPaddingTop
      ? `padding: 0 0 2em;
    `
      : `  padding: 2em 0;
`}
`

export const ImageWrapper = styled.div`
  border-radius: 20px;
  height: 685px;
  background-color: white;
  ${({ theme }) => theme.breakpoints.down("lg")} {
    height: auto;
  }
`

export const Image = styled(CustomImage)`
  width: 100%;
  height: 685px;
  border-radius: 20px;
  ${({ theme }) => theme.breakpoints.down("lg")} {
    height: auto;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
    border-radius: 20px;
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    img {
      object-fit: contain !important;
      border-radius: 20px !important;
    }
  }
`

export const ContentWrapper = styled.div`
  border-radius: 20px;
  background-color: white;
  height: 100%;
`
