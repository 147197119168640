import styled from "styled-components"
import { Typography } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Title = styled.h2`
  color: #09090b;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 36px;
  margin-bottom: 1em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
    margin-bottom: 0.5em;
  }
`

export const Description = styled(Typography)`
  color: #566171;
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  letter-spacing: 0;
  line-height: 32px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 0 0 1em;
    font-size: 1rem;
    line-height: normal;
  }
`

export const Wrapper = styled.div`
  margin-top: -8px;
  background-color: #f5f5f5;
  padding: 2em 0;
`

export const Logo = styled(CustomImage)`
  max-width: 200px;
  padding: 2em 0;
`

export const Tablet = styled(CustomImage)`
  max-width: 100%;
  width: 100%;
  height: 75%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
    object-position: bottom;
  }
`

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 618px;
  background-color: white;
  border-radius: 20px;
  padding: 2em 2em 0;
  ${({ theme }) => theme.breakpoints.down("md")} {
    height: auto;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  //justify-content: center;
`

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Image = styled(CustomImage)`
  height: 618px;
  img {
    border-radius: 20px;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    height: auto;
    img {
      object-fit: contain !important;
    }
  }
`
