//Frames Array
let images = []

let url =
  "https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/Home-V2-Toon.20."

for (let i = 100; i < 150; i++) {
  let image = {
    image: url.concat((i + 1).toString(), ".png"),
  }
  images.push(image)
}

export default images
