import React from "react"
import * as S from "./flow-charts-section.styles"
import { Container, Grid } from "@mui/material"
import HomeTabs from "../../components/home-tabs/home-tabs.component"
import CustomButton from "../../components/custom-button/custom-button.component"
const FlowChartsSection = ({
  title,
  description,
  rightTitle,
  button,
  rightDescription,
  tabs,
}) => {
  return (
    <>
      <S.TopWrapper>
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            <Grid item xs={12} lg={8}>
              <S.TextWrapper>
                <HomeTabs
                  subtitle={rightTitle}
                  description={rightDescription}
                  tabs={tabs}
                />
              </S.TextWrapper>
            </Grid>
            <Grid item xs={12} lg={4}>
              <S.TextWrapper>
                {title && <S.Title>{title}</S.Title>}
                {description && <S.Description>{description}</S.Description>}
                <CustomButton href={button?.url} className="black-transparent">
                  {button?.title}
                </CustomButton>
              </S.TextWrapper>
            </Grid>
          </Grid>
        </Container>
      </S.TopWrapper>
      {/*<S.Wrapper>*/}
      {/*  <Container maxWidth="xl">*/}
      {/*    <Grid container direction="row-reverse" spacing={4}>*/}
      {/*      <S.LeftWrapper item md={4}>*/}
      {/*        <S.TextWrapper>*/}
      {/*          {title && <S.Title>{title}</S.Title>}*/}
      {/*          {description && <S.Description>{description}</S.Description>}*/}
      {/*          {image && <S.Image img={image} />}*/}
      {/*        </S.TextWrapper>*/}
      {/*      </S.LeftWrapper>*/}
      {/*      <S.TabGrid item md={8}>*/}
      {/*        <VerticalTabs*/}
      {/*          subtitle={rightTitle}*/}
      {/*          description={rightDescription}*/}
      {/*          tabs={tabs}*/}
      {/*        />*/}
      {/*      </S.TabGrid>*/}
      {/*    </Grid>*/}
      {/*  </Container>*/}
      {/*</S.Wrapper>*/}
    </>
  )
}

export default FlowChartsSection
