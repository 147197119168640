import styled from "styled-components"
import CustomImage from "../custom-image/custom-image.component"
import { Typography } from "@mui/material"
import CustomButton from "../custom-button/custom-button.component"

export const Card = styled.div`
  background-color: white;
  border-radius: 20px;
  min-height: 920px;
  padding: 6em 0 4em;
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.between("md", "lg")} {
    padding: 2em;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    height: 100%;
    min-height: unset;
    padding: 2em 1em;
  }
`

export const ForgeIcon = styled(CustomImage)`
  max-width: 120px;
  margin-bottom: 1em;
  img {
    width: 100%;
    height: 100%;
  }
`

export const CardContent = styled.div`
  max-width: 450px;
  width: 100%;
`

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.fonts.secondary};
  line-height: 48px;
  font-weight: 500;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const KitImage = styled(CustomImage)`
  max-height: 300px;
  margin: 2em 0;
  img {
    object-fit: contain !important;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin: 0;
  }
`

export const Description = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  color: ${({ theme }) => theme.palette.text.content};
  line-height: 32px;
  span {
    font-weight: bold;
  }
  ul {
    margin-top: 0;
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
`

export const Button = styled(CustomButton)`
  margin-top: 1em;
  width: 100%;
  @media (min-width: 644px) and (max-width: 715px) {
    font-size: 14px;
  }
  @media (min-width: 600px) and (max-width: 644px) {
    font-size: 12px;
  }
`
