import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { ButtonBase, Grid, Typography } from "@mui/material"

export const Wrapper = styled(SectionWrapper)`
  background-color: #f5f5f5;
  padding: 0;
`

export const TopWrapper = styled.div`
  background-color: white;
  padding: 4em 0;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-bottom: 1em;
  }
`

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.typography.pxToRem(48)};
  font-weight: 500;
  line-height: 48px;
  margin-bottom: 0.5em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.content};
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  line-height: 32px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
`

export const TextWrapper = styled.div`
  max-width: 880px;
  text-align: center;
  margin: auto;
`

export const CardsWrapper = styled.div`
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #ffffff 25%,
    #f5f5f5 25%,
    #f5f5f5 100%
  );
  ${({ theme }) => theme.breakpoints.up("md")} {
    background: linear-gradient(
      to bottom,
      #ffffff 0%,
      #ffffff 50%,
      #f5f5f5 50%,
      #f5f5f5 100%
    );
  }
`

export const Card = styled(ButtonBase)`
  height: 100%;
  border-radius: 20px;
  width: 100%;
  background-color: #15151a;
  padding: 3em;
  display: flex;
  flex-direction: column;
  text-align: left;

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 1.5em;
    min-height: unset;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    min-height: 200px;
  }
`

export const CardTitle = styled.h3`
  color: white;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 0.5em;
  text-align: center;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const CardDescription = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  color: white;
  text-align: center;
  max-width: 325px;
  margin: auto;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
  ul {
    padding: 0 0 0 1em;
    li {
      color: white;
    }
  }
`

export const CustomGrid = styled(Grid)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    &.MuiGrid-item {
      padding-top: 0;
    }
  }
`
