import React, { useRef, useState } from "react"
import * as S from "./distributor-form.styles"
import { useForm } from "react-hook-form"
import ReCAPTCHA from "react-google-recaptcha"
import Typography from "@mui/material/Typography"
import CustomButton from "../custom-button/custom-button.component"
import { submitGravityForm } from "../../utils"
import { useLocation } from "@reach/router"
import Spinner from "../spinner/spinner.component"
import { Grid, MenuItem } from "@mui/material"
import ThankYouMessage from "../thank-you-message/thank-you-message.component"

const DistributorForm = () => {
  // const recaptchaRef = useRef()
  const location = useLocation()
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  // const handleCaptchaChange = () => {
  //   setError(false)
  // }
  const distributors = [
    {
      name: "Forge/OS Distributor",
    },
    {
      name: "Automation Integrator",
    },
  ]

  const regions = [
    { name: "North East" },
    { name: "South East" },
    { name: "Mid West" },
    { name: "South" },
    { name: "Mountain" },
    { name: "Pacific" },
  ]

  const [partnerType, setPartnerType] = useState(distributors[0].name)
  const [region, setRegion] = useState(regions[0].name)

  const handleChangePartnerType = event => {
    setPartnerType(event.target.value)
  }

  const handleChangeRegion = event => {
    setRegion(event.target.value)
  }

  const { register, handleSubmit } = useForm()
  const onSubmit = data => {
    setIsLoading(true)
    // const captchaVerification = recaptchaRef.current.getValue()
    // if (captchaVerification === "") {
    //   setError(true)
    //   setIsLoading(false)
    //   return
    // }
    submitGravityForm(
      {
        input_1: data.first_name,
        input_2: data.last_name,
        input_4: data.email,
        input_5: data.company,
        input_6: location.href,
        input_7: data.region,
        input_8: data.partner_type,
      },
      12
    ).then(response => {
      setIsLoading(false)
      setFormSubmitted(true)
    })
  }
  return (
    <S.CustomForm onSubmit={handleSubmit(onSubmit)}>
      {isLoading && <Spinner />}{" "}
      {!formSubmitted ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <S.CustomField
                required
                id="firstname"
                label="First Name"
                variant="standard"
                {...register("first_name")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <S.CustomField
                required
                id="lastname"
                label="Last Name"
                variant="standard"
                {...register("last_name")}
              />
            </Grid>
            <Grid item xs={12}>
              <S.CustomField
                required
                id="email"
                label="Email Address"
                variant="standard"
                {...register("email")}
              />
            </Grid>
            <Grid item xs={12}>
              <S.CustomField
                required
                id="company"
                label="Company"
                variant="standard"
                {...register("company")}
              />
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "1em" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <S.CustomField
                    select
                    value={region}
                    id="region"
                    label="Region"
                    variant="standard"
                    {...register("region")}
                    onChange={handleChangeRegion}
                  >
                    {regions.map(({ name }, index) => (
                      <MenuItem key={`state-${name}`} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </S.CustomField>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <S.CustomField
                    select
                    value={partnerType}
                    id="partnerType"
                    label="Partner Type"
                    variant="standard"
                    {...register("partner_type")}
                    onChange={handleChangePartnerType}
                  >
                    {distributors.map(({ name }, index) => (
                      <MenuItem key={`type-${index}`} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </S.CustomField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <S.TermsDiv>
            {/*<ReCAPTCHA*/}
            {/*  sitekey={process.env.GATSBY_CAPTCHA_CLIENT_KEY}*/}
            {/*  size="normal"*/}
            {/*  render="explicit"*/}
            {/*  onChange={handleCaptchaChange}*/}
            {/*  className="g-recaptcha"*/}
            {/*  ref={recaptchaRef}*/}
            {/*/>*/}
            {error && (
              <Typography style={{ color: "#F54343" }}>
                Please enter captcha
              </Typography>
            )}
          </S.TermsDiv>
          <S.Link url="/privacy-policy/">Terms and Privacy Policy.</S.Link>
          <S.ButtonDiv>
            <CustomButton className="blue" children="Subscribe" type="submit" />
          </S.ButtonDiv>
        </>
      ) : (
        <ThankYouMessage message={`<h3>Thanks</h3>`} />
      )}
    </S.CustomForm>
  )
}

export default DistributorForm
