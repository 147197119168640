import React from "react"
import * as S from "./activation-section.styles"
import { Container, useMediaQuery, useTheme } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import { graphql, useStaticQuery } from "gatsby"
import ActivationForm from "../../components/activation-form/activation-form.component"

const ActivationSection = ({ title, backgroundImage }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
  const staticQuery = useStaticQuery(graphql`
    query {
      activationImage: file(relativePath: { eq: "activation.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  return !isMobile ? (
    <S.Wrapper img={backgroundImage}>
      <Container sx={{ height: "100%" }}>
        <S.ContentWrapper>
          <ActivationForm title={title} />
        </S.ContentWrapper>
      </Container>
    </S.Wrapper>
  ) : (
    <S.MobileWrapper>
      <CustomImage img={staticQuery.activationImage} />
      <Container>
        <ActivationForm title={title} />
      </Container>
    </S.MobileWrapper>
  )
}

export default ActivationSection
