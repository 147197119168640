import React, { useState } from "react"
import * as S from "./solutions-section.styles"
import { Container, Grid } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"
import BuyButton from "../../components/buy-button/buy-button.component"

const SolutionsSection = ({ title, description, button, buttons }) => {
  const [imageIndex, setImageIndex] = useState(0)
  const handleChange = index => {
    setImageIndex(index)
  }
  if (!buttons) return null
  return (
    <S.Wrapper>
      <Container>
        <S.TopWrapper>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              {title && <S.Title>{title}</S.Title>}
              {button?.title && (
                <CustomButton
                  className="black-transparent"
                  href={button?.url}
                  target={button?.target}
                >
                  {button?.title}
                </CustomButton>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              {description && <S.Description>{description}</S.Description>}
            </Grid>
          </Grid>
        </S.TopWrapper>

        <Grid container spacing={4}>
          <S.ButtonsGrid item xs={12} sm={6} md={4}>
            {buttons.map(({ text }, index) => (
              <BuyButton
                active={index === imageIndex}
                handleChange={handleChange}
                index={index}
                key={`buy-button-${index}`}
                text={text}
              />
            ))}
          </S.ButtonsGrid>
          <Grid item xs={12} sm={6} md={8}>
            <S.ImageWrapper>
              <S.Image img={buttons[imageIndex].image} />
            </S.ImageWrapper>
          </Grid>
        </Grid>
      </Container>
    </S.Wrapper>
  )
}

export default SolutionsSection
