import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Typography } from "@mui/material"

export const Wrapper = styled(SectionWrapper)`
  padding: 4em 0 2em;
  background-color: #f5f5f5;
`

export const BannerWrapper = styled.div`
  background-color: ${props => props.color};
  padding: 5em 0;
  border-radius: 20px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 1.5em;
  }
`

export const ContentWrapper = styled.div`
  max-width: 950px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
`

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: white;
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const Description = styled(Typography)`
  color: white;
  opacity: 0.6;
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  line-height: 24px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
`
