import React, { useState } from "react"
import { useForm } from "react-hook-form"
import * as S from "./activation-form.styles"
import Spinner from "../spinner/spinner.component"
import { isBrowser, submitOfflineActivation } from "../../utils"
import CustomButton from "../custom-button/custom-button.component"
import { IconButton, Tooltip, useMediaQuery, useTheme } from "@mui/material"
import { ContentCopy } from "@mui/icons-material"

const ActivationForm = ({ title }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [licenseCode, setLicenseCode] = useState("")
  const [isCopied, setIsCopied] = useState(false)
  const [isError, setIsError] = useState(false)
  const theme = useTheme()
  const { register, handleSubmit } = useForm()
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"))
  const handleCopy = () => {
    if (isBrowser()) {
      navigator.clipboard.writeText(licenseCode)
      setIsCopied(true)
    }
  }
  const onSubmit = data => {
    setIsLoading(true)
    submitOfflineActivation(data.code).then(response => {
      setIsLoading(false)
      if (response.data) {
        setFormSubmitted(true)
        setLicenseCode(response.data?.message)
      } else {
        setIsError(true)
      }
    })
  }

  return (
    <>
      {isLoading && <Spinner />}
      <S.TextWrapper onSubmit={handleSubmit(onSubmit)}>
        {!formSubmitted ? (
          <>
            {title && <S.Title>{title}</S.Title>}
            <S.CustomInput
              id="code"
              sx={{ input: { color: "white" } }}
              multiline
              rows={6}
              fullWidth
              placeholder="Paste your activation code to start"
              variant="standard"
              required
              {...register("code")}
              InputProps={{
                disableUnderline: true,
              }}
            />
            {isError && <S.Error>Invalid Code</S.Error>}
            <S.ButtonWrapper>
              <CustomButton
                fullWidth={isSmallDevice}
                className="blue"
                type="submit"
              >
                Submit
              </CustomButton>
            </S.ButtonWrapper>
          </>
        ) : (
          <>
            <S.Title>Activation Successful </S.Title>
            <S.CustomContainer>
              <h2>License</h2>
              <Tooltip
                title={isCopied ? "Copied" : "Copy To Clipboard"}
                placement="top"
              >
                <IconButton onClick={handleCopy}>
                  <ContentCopy />
                </IconButton>
              </Tooltip>
            </S.CustomContainer>
            <S.CustomInput
              sx={{ input: { color: "white" } }}
              multiline
              rows={10}
              readonly
              fullWidth
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              value={licenseCode}
            />
          </>
        )}
      </S.TextWrapper>
    </>
  )
}

export default ActivationForm
