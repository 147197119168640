import styled from "styled-components"
import { Typography } from "@mui/material"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const TopWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2em;
  border-radius: 20px;
  background-color: white;
`

export const TextWrapper = styled.div``

export const Title = styled.h2`
  color: #09090b;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 36px;
  margin-bottom: 1em;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const Description = styled(Typography)`
  color: #566171;
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  letter-spacing: 0;
  line-height: 32px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
    margin-bottom: 1em;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const Wrapper = styled(SectionWrapper)`
  background-color: #f5f5f5;
  padding: 0 0 2em;
`

export const Video = styled.video`
  width: 100%;
  border-radius: 20px;
  max-height: 300px;
  object-fit: cover;
  ${({ theme }) => theme.breakpoints.up("md")} {
    height: calc(100vh - 310px);
    min-height: 600px;
    max-height: 685px;
  }
`
