import styled from "styled-components"
import { TableCell, TableContainer } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import ClearIcon from "@mui/icons-material/Clear"

export const TitleTableCell = styled(TableCell)`
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  line-height: 23px;
  &.MuiTableCell-root {
    padding: 1em 0;
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
`

export const ContentTableCell = styled(TableCell)`
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  color: ${({ theme }) => theme.palette.text.content};
  &.MuiTableCell-root {
    padding: 0.5em;
    min-width: 200px;
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
`

export const Check = styled(CheckIcon)`
  fill: ${({ theme }) => theme.palette.secondary.main};
`

export const Cross = styled(ClearIcon)`
  fill: red;
`

export const CustomTableContainer = styled(TableContainer)`
  // height: 250px;
  // ${({ theme }) => theme.breakpoints.down("md")} {
  //   height: 250px;
  // }
  // ${({ theme }) => theme.breakpoints.down("sm")} {
  //   height: unset;
  // }
`
