import styled from "styled-components"
import { TableCell, TableContainer } from "@mui/material"

export const DescriptionCell = styled(TableCell)`
  color: white;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  min-width: 250px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  &.bold {
    font-weight: 500;
  }

  &.blue {
    color: ${({ theme }) => theme.palette.secondary.main};
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    min-width: unset;
    font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  }
`

export const CustomTableContainer = styled(TableContainer)`
  height: 240px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    height: 450px;
  }

  ${({ theme }) => theme.breakpoints.between("xs", "sm")} {
    height: 480px;
  }
`

export const ExternalLink = styled.a`
  color: white;
`

export const HeadTableCell = styled(TableCell)`
  background-color: #2d2d33;
  color: white;
  border-bottom: none;
  font-weight: bold;
`
