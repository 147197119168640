import React from "react"
import * as S from "./empower-module.styles"
import CustomButton from "../../components/custom-button/custom-button.component"
import { Container, Grid } from "@mui/material"

const EmpowerModule = ({ title, button, description }) => {
  return (
    <S.Wrapper noPaddingTop>
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item lg={4} md={12} xs={12}>
            <S.TopWrapper>
              <S.TextWrapper>
                {title && <S.Title>{title}</S.Title>}
                {description && <S.Description>{description}</S.Description>}
              </S.TextWrapper>
              {button?.url && button?.title && (
                <S.ButtonWrapper>
                  <CustomButton
                    className="black-transparent"
                    href={button?.url}
                    target={button?.target}
                  >
                    {button?.title}
                  </CustomButton>
                </S.ButtonWrapper>
              )}
            </S.TopWrapper>
          </Grid>
          <Grid item lg={8} md={12} xs={12}>
            <S.Video playsInline autoPlay loop muted>
              <source
                src="https://admin-rr.aftershock.agency/wp-content/uploads/2022/02/empower-video.mp4"
                type="video/mp4"
              />
            </S.Video>
          </Grid>
        </Grid>
      </Container>
    </S.Wrapper>
  )
}

export default EmpowerModule
