import styled from "styled-components"
import { Grid, Typography, Button } from "@mui/material"
import CustomImage from "../custom-image/custom-image.component"
import Tab from "@mui/material/Tab"
import ArrowNext from "../../images/arrow.svg"
import BlackArrow from "../../images/arrow-black.svg"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

export const CustomGrid = styled(Grid)``

export const Title = styled.h4`
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(22)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 31px;
  &.large {
    font-size: ${({ theme }) => theme.typography.pxToRem(28)};
    line-height: 48px;
    padding-bottom: 0.25em;
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`
export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.content};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  letter-spacing: 0;
  line-height: 32px;
  padding: 1em 0 2em 0;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
    padding-bottom: 1em;
  }
`

export const ContentWrapper = styled.div``

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TabImage = styled(CustomImage)`
  max-height: 600px;
  width: 100%;
  img {
    max-height: 600px;
    width: 100%;
    object-fit: contain !important;
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    height: 400px;
    width: 300px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain !important;
    }
  }
`
export const CustomTab = styled(Tab)`
  color: ${({ theme }) => theme.palette.text.primary};
  align-items: flex-start;
  text-transform: capitalize;
  border-radius: 50px;
  max-width: unset;
  &.device-control,
  &.Mui-selected {
    ::after {
      content: "";
      position: absolute;
      right: 1em;
      height: 40px;
      width: 40px;
      background-size: contain;
      ${({ theme }) => theme.breakpoints.down("md")} {
        height: 20px;
        width: 20px;
        right: 1.5em;
      }
    }
  }
  &.device-control {
    ::after {
      background: url(${BlackArrow}) no-repeat center center;
    }
  }
  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: #ffffff;
    ::after {
      background: url(${ArrowNext}) no-repeat center center;
      transform: rotate(180deg);
    }
  }
  :not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

export const MediaWrapper = styled.div`
  height: 600px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: 400px;
  }
`

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain !important;
  border: none;
`

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  svg {
    fill: white;
    width: 25px;
    height: 25px;
  }
`

export const TabButton = styled(Button)`
  margin-bottom: 1em;
  border-radius: 20px;
  text-transform: capitalize;
  padding: 0.3em 1.5em;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 0.3em 2em;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
  }

  &.disabled {
    background-color: white;
    color: black;
  }

  &.selected {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: white;
  }
`

export const Arrow = styled(ArrowForwardIcon)``

export const LeftGrid = styled(Grid)`
  justify-content: center;
  display: flex;
  flex-direction: column;
`
