import React from "react"
import * as S from "./buy-button.styles"

import PropTypes from "prop-types"

const BuyButton = ({ text, index, handleChange, active }) => {
  return (
    <S.Button
      onClick={() => handleChange(index)}
      className={active ? "active" : ""}
    >
      {text && <S.Text>{text}</S.Text>}
      <S.IconWrapper />
    </S.Button>
  )
}

BuyButton.propTypes = {
  text: PropTypes.string.isRequired,
}

export default BuyButton
