import styled from "styled-components"
import { Typography } from "@mui/material"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"

export const FeatureWrapper = styled.div`
  background-color: white;
  cursor: pointer;
  transition: 0.3s;
  &.active {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`

export const TitleWrapper = styled.div`
  padding: 2em 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(151, 151, 151, 0.5);
`

export const Title = styled.h2`
  color: #09090b;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 36px;
`

export const Counter = styled.span`
  opacity: 0.5;
  color: #000000;
  font-family: ${({ theme }) => theme.fonts.tertiary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  letter-spacing: 0;
  line-height: 21px;
`

export const DescriptionWrapper = styled.div`
  padding: 2em 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 666px;
`

export const DescriptionCounter = styled.span`
  color: #ffffff;
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  letter-spacing: 0;
  line-height: 24px;
`

export const LeftTitle = styled.h2`
  color: #ffffff;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 36px;
  padding: 0.5em 0 1em;
`

export const Description = styled(Typography)`
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
`

export const CloseIcon = styled(CancelOutlinedIcon)`
  position: absolute;
  top: 3.2em;
  right: 10px;
  fill: white;
`
