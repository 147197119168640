import React from "react"
import * as S from "./info-section.styles"
import { Container, Grid } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import parse from "html-react-parser"

const InfoSection = ({ title, description, image }) => {
  return (
    <S.Wrapper>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={6}>
            <S.ContentWrapper>
              <S.Title>{parse(title)}</S.Title>
              <S.Description>{description}</S.Description>
            </S.ContentWrapper>
          </Grid>
          <Grid item xs={6}>
            <S.RightWrapper>
              <S.Image img={image} />
            </S.RightWrapper>
          </Grid>
        </Grid>
      </Container>
    </S.Wrapper>
  )
}

export default InfoSection
