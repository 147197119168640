import styled, { css } from "styled-components"
import { Fade } from "react-awesome-reveal"
import { Typography } from "@mui/material"

const height = css`
  height: 100vh;
  min-height: 650px;
`

export const StickyDiv = styled.div`
  overflow: hidden;
  background-color: white;
`

export const Sticky = styled.div`
  height: 100vh;
  width: 100%;
  margin-top: 80px;
  background-color: white;
`

export const CustomFade = styled(Fade)`
  ${height};
  position: relative;
  z-index: 2;
`

export const Animation = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  text-align: center;
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.content};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  padding: 1em 0;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  margin: auto;
`
