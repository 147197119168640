import styled from "styled-components"
import CustomImage from "../custom-image/custom-image.component"

export const TopWrapper = styled.div`
  height: 600px;
  width: 900px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 600px;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 350px;
  }
`

export const TableWrapper = styled.div`
  padding: 0 4em;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 0 1em;
  }
`

export const MapWrapper = styled.div`
  position: relative;
  height: 300px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    height: 120px;
  }
`

export const InfoWrapper = styled.div`
  padding: 0 4em;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 0 1em;
  }
`

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(36)};
  color: white;
  line-height: 36px;
  margin-bottom: 0.5em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1.5rem;
    line-height: normal;
  }
`

export const Subtitle = styled.h3`
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  color: white;
  line-height: 24px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 1rem;
    line-height: normal;
  }
`

export const ImageWrapper = styled.div`
  position: absolute;
  top: -47px;
  left: -93px;
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.breakpoints.down("md")} {
    display: none;
  }
`

export const Image = styled(CustomImage)`
  max-height: 300px;
  img {
    max-height: 300px;
    object-fit: contain !important;
  }
`
