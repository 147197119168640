import React, { useState } from "react"
import * as S from "./features.styles"
import { Container } from "@mui/material"
import FeaturesCard from "../../components/features-card/features-card.component"

const Features = ({ title, description, features }) => {
  const [expanded, setExpanded] = useState(false)
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <S.Wrapper>
      <Container maxWidth="md">
        <S.ContentWrapper>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
        </S.ContentWrapper>
      </Container>
      {features.map(({ title, description }, index) => (
        <S.CustomAccordion
          key={index - title}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
        >
          <S.CustomAccSummary>
            <S.ItemWrapper maxWidth="md">
              <S.AccordionWrapper>
                <S.ItemTitle className="titleSummary">{title}</S.ItemTitle>
                <S.ItemDescription className="counterSummary">
                  {index + 1 < 10 ? `0${index + 1}` : index + 1}
                </S.ItemDescription>
              </S.AccordionWrapper>
            </S.ItemWrapper>
          </S.CustomAccSummary>
          <S.CustomAccDetails>
            <S.ItemWrapper maxWidth="md" className="summary">
              <S.DescriptionWrapper>
                <S.LeftWrapper>
                  <S.DescriptionCounter>
                    {index+1 < 10 && 0}
                    {index+1}
                  </S.DescriptionCounter>
                  <S.LeftTitle>{title}</S.LeftTitle>
                  <S.LeftDescription>{description}</S.LeftDescription>
                </S.LeftWrapper>
                <S.CloseIcon onClick={handleChange(`panel${index}`)} />
              </S.DescriptionWrapper>
            </S.ItemWrapper>
          </S.CustomAccDetails>
        </S.CustomAccordion>
      ))}
    </S.Wrapper>
  )
}

export default Features
