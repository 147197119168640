import styled from "styled-components"

export const Skeleton = styled.span`
  background-color: rgba(240, 240, 240, 1);
  background-color: white;
  background-image: linear-gradient(
    90deg,
    rgba(240, 240, 240, 1),
    rgba(240, 240, 240, 1),
    rgba(255, 255, 255, 1),
    rgba(240, 240, 240, 1),
    rgba(240, 240, 240, 1)
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 2px;
  display: inline-block;
  line-height: 1;
  width: 100%;
`
