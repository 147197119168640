import React from "react"
import * as S from "./distributor-map.styles"
import { Grid } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import CustomImage from "../custom-image/custom-image.component"
import DistributorForm from "../distributor-form/distributor-form.component"

const DistributorMap = () => {
  const staticQuery = useStaticQuery(graphql`
    query {
      map: file(relativePath: { eq: "us-map.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  return (
    <S.TopWrapper>
      <S.Title>Forge/OS Resellers</S.Title>
      <S.Description>Find a READY Distribution partner near you</S.Description>
      <S.Image img={staticQuery.map} />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6}>
          <S.LeftText>
            Contact us to find READY Distributor or Integrator Partner near you.
          </S.LeftText>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <DistributorForm />
        </Grid>
      </Grid>
    </S.TopWrapper>
  )
}

export default DistributorMap
