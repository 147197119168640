import styled, { css } from "styled-components"
import TextField from "@mui/material/TextField"
import CustomLink from "../custom-link/custom-link.component"

const textInputStyle = css`
  color: ${({ theme }) => theme.palette.text.content};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(20)};
`

export const CustomForm = styled.form`
  //overflow-y: scroll;
`

export const CustomField = styled(TextField)`
  width: 100%;

  .MuiInputBase-root {
    color: #566171;
    border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.main};
  }
  .MuiInputLabel-root {
    ${textInputStyle}
  }
`

export const TermsDiv = styled.div`
  margin: 1em 0;
`

export const Link = styled(CustomLink)`
  text-decoration: underline;
  color: black;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
`

export const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`
