import React from "react"
import * as S from "./buy-forge-hero.styles"
import { Container, Grid, useMediaQuery, useTheme } from "@mui/material"
import KitCard from "../../components/kit-card/kit-card.component"

const BuyForgeHero = ({ title, backgroundImage, cards }) => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <S.Wrapper>
      <S.MainWrapper>
        <S.ImageWrapper>
          <S.Image img={backgroundImage} />
          {title && <S.Title>{title}</S.Title>}
        </S.ImageWrapper>

        <S.GrayWrapper />
        <S.CardWrapper>
          <Container>
            <Grid container spacing={isTablet ? 4 : 6}>
              {cards.map(
                ({ title, description, image, features, button }, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    key={`kit-card-${index}`}
                  >
                    <KitCard
                      title={title}
                      description={description}
                      image={image}
                      features={features}
                      button={button}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </Container>
        </S.CardWrapper>
      </S.MainWrapper>
    </S.Wrapper>
  )
}

export default BuyForgeHero
